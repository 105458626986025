import { AuditModule, AuditModuleCategory } from '@Types/Module';
import { getTextColor } from '@Utils/color.utils';
import { isEqual, sortBy, uniq } from 'lodash';
import { Chart } from 'primereact/chart';
import { memo, useMemo } from 'react';
import styled from 'styled-components';

type Result = {
    module: AuditModule;
    score: {
        [theme: string]: number;
    };
};

type Props = {
    categories: AuditModuleCategory[];
    results: [Result] | [Result, Result];
};
export const AuditGraph = memo(
    (props: Props) => {
        const themes = useMemo(() => {
            return props.results
                .map((result) => result.score)
                .map((result) => Object.keys(result))
                .flat()
                .map((k) => props.categories.find((t) => t.label === k))
                .filter(Boolean) as AuditModuleCategory[];
        }, [props.results, props.categories]);

        const sorted = useMemo(() => {
            return uniq(sortBy(themes, 'label'));
        }, [themes]);

        const chartData = useMemo(() => {
            return {
                labels: sorted.map((t) => t.label),
                datasets:
                    props.results.length === 1
                        ? [
                              {
                                  type: 'polarArea',
                                  data: sorted.map((t) => 100 * (props.results[0].score[t.label] ?? 0)),
                                  backgroundColor: sorted.map((t) => t.color + '88'),
                                  label: 'Résultat',
                              },
                          ]
                        : [
                              {
                                  type: 'polarArea',
                                  data: sorted.map((t) => 100 * (props.results[1]!.score[t.label] ?? 0)),
                                  backgroundColor: sorted.map((t) => t.color + '50'),
                              },
                              {
                                  type: 'polarArea',
                                  data: sorted.map((t) => 100 * (props.results[0].score[t.label] ?? 0)),
                                  borderColor: sorted.map((t) => t.color),
                                  borderWidth: 3,
                                  borderDash: [5, 10],
                                  backgroundColor: sorted.map((t) => t.color + '00'),
                                  borderAlign: 'inner',
                              },
                          ],
            };
        }, [props.results, sorted]);

        const plugin = {
            id: 'custom_canvas_background_color',
            beforeDraw: (chart: any) => {
                const { ctx } = chart;
                ctx.save();
                ctx.globalCompositeOperation = 'destination-over';
                ctx.fillStyle = 'white';
                ctx.fillRect(0, 0, chart.width, chart.height);
                ctx.restore();
            },
        };

        return (
            <div className="mx-auto w-full" style={{ maxWidth: 450 }}>
                <Chart
                    type="polarArea"
                    data={chartData}
                    options={{
                        responsive: true,
                        maintainAspectRatio: true,
                        plugins: {
                            legend: {
                                position: 'top',
                            },
                        },
                        scales: {
                            // write scales for polarArea

                            r: {
                                beginAtZero: true,
                                min: 0,
                                max: 100,
                                ticks: {
                                    stepSize: 10,
                                    z: 2,
                                },
                                grid: {
                                    z: 1,
                                },
                                pointLabels: {
                                    backdropColor: sorted.map((t) => t.color),
                                    color: sorted.map((t) => getTextColor(t.color)),
                                },
                            },
                        },
                    }}
                    plugins={[plugin]}
                />
                {props.results.length > 1 && (
                    <div className="flex gap-3 justify-content-center mt-3">
                        <div className="flex gap-1 align-items-center">
                            <LegendRect color="#555" dashed />
                            <span className="he-paragraph--small">Résultats audit clinique initial</span>
                        </div>
                        <div className="flex gap-1 align-items-center">
                            <LegendRect color="#555555" />
                            <span className="he-paragraph--small">Résultats audit clinique final</span>
                        </div>
                    </div>
                )}
            </div>
        );
    },
    (prev, next) => {
        return isEqual(prev.results, next.results) && isEqual(prev.categories, next.categories);
    }
);


const LegendRect = styled.div<{ color: string, dashed?: boolean }>`
    width: 35px;
    height: 15px;
    background-color: ${(props) => props.color+'50'};
    ${props => {
        if (props.dashed) return `border: 2px dashed ${props.color};`
        return ""
    }}
    border-radius: 2px;
`;