import { numberToString } from '@Utils/string.utils';
import { useMemo } from 'react';
import styled from 'styled-components';

type Props = {
    score: number;
    initialScore: number | null;
    height?: number;
    backgroundColor?: string;
    oldScoreColor?: string;
    successColor?: string;
    errorColor?: string;
    neutralColor?: string;
    cursorColor?: string;
    cursorTextColor?: string;
};

export const ScoreProgress = (props: Props) => {
    const {
        score,
        initialScore,
        height = 8,
        backgroundColor = '#f0f0f0',
        oldScoreColor = '#b0bec5',
        neutralColor = '#2b9dd6',
        successColor = '#76d87b',
        errorColor = '#f06868',
        cursorColor = '#cfd8dc',
        cursorTextColor = '#90a4ae',
    } = props;

    const progressBar = useMemo(() => {
        if (!initialScore)
            return (
                <>
                    <div className="flex h-full w-full">
                        <ProgressBar width={score * 100} color={neutralColor} height={height} />
                    </div>
                    <RoundCursor position={score * 100} color={neutralColor} height={height}></RoundCursor>
                </>
            );

        const diff = Math.abs(score - initialScore);

        if (initialScore > score) {
            return (
                <>
                    <div className="flex h-full w-full">
                        <ProgressBar width={score * 100} color={oldScoreColor} height={height} />
                        <ProgressBar width={diff * 100} color={errorColor} height={height} />
                    </div>
                    <RoundCursor position={score * 100} color={errorColor} height={height}>
                        <CursorText position="top" color={errorColor}>
                            - {numberToString(diff * 100, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
                            &nbsp;
                            <i className="pi pi-caret-down down" />
                        </CursorText>
                    </RoundCursor>
                </>
            );
        }

        return (
            <>
                <RoundCursor position={score * 100} color={successColor} height={height}>
                    <CursorText position="top" color={successColor}>
                        + {numberToString(diff * 100, { minimumFractionDigits: 1, maximumFractionDigits: 1 })}
                        &nbsp;
                        <i className="pi pi-caret-up up" />
                    </CursorText>
                </RoundCursor>
                <div className="flex h-full w-full">
                    <ProgressBar width={initialScore * 100} color={oldScoreColor} height={height} />
                    <ProgressBar width={diff * 100} color={successColor} height={height} />
                </div>
            </>
        );
    }, [score, initialScore, oldScoreColor, successColor, errorColor, cursorTextColor, height]);

    return (
        <div className="w-full flex align-items-center gap-3" style={{ flex: 1 }}>
            <ProgressBarContainer height={height} backgroundColor={backgroundColor}>
                {progressBar}
                {Array(10)
                    .fill(0)
                    .map(
                        (_, i, arr) =>
                            i > 0 && (
                                <Cursor key={i} position={(i * 100) / arr.length} color={cursorColor} height={height}>
                                    <CursorText position="bottom" color={cursorTextColor}>
                                        {(i * 100) / arr.length}
                                    </CursorText>
                                </Cursor>
                            )
                    )}
            </ProgressBarContainer>
        </div>
    );
};

const ProgressBarContainer = styled.div<{ height: number; backgroundColor: string }>`
    height: ${(props) => props.height}px;
    width: 100%;
    background-color: ${(props) => props.backgroundColor};
    border-radius: 4px;
    position: relative;
`;

const ProgressBar = styled.div<{ width: number; color?: string; height: number }>`
    height: 100%;
    width: ${(props) => props.width}%;
    background-color: ${(props) => props.color || '#3c8dbc'};
    height: ${(props) => props.height}px;

    &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }
`;

const Cursor = styled.div<{ position: number; color?: string; height: number }>`
    height: ${(props) => props.height + 4}px;
    width: 2px;
    background-color: ${(props) => props.color || 'white'};
    position: absolute;
    top: -2px;
    left: ${(props) => props.position}%;
    transform: translateX(-50%);
`;

const RoundCursor = styled.div<{ position: number; color?: string; height: number }>`
    height: ${(props) => props.height + 6}px;
    width: ${(props) => props.height + 6}px;
    background-color: ${(props) => props.color || '#aaa'};
    border-radius: 50%;
    position: absolute;
    top: -3px;
    border: 2px solid #fff;
    outline: 2px solid ${(props) => props.color || '#aaa'};
    left: ${(props) => props.position}%;
    transform: translateX(-50%);
    box-sizing: border-box;
    z-index: 1;
`;

const CursorText = styled.div<{ position: 'top' | 'bottom'; color?: string }>`
    position: absolute;
    top: ${(props) => (props.position === 'top' ? '-20px' : '20px')};
    line-height: 100%;
    transform: translateX(-50%);
    left: 50%;
    font-size: 12px;
    color: ${(props) => props.color || '#aaa'};
    width: max-content;
    display: flex;
    align-items: center;
    font-family: 'roboto', sans-serif;
    i {
        font-size: 12px;
        color: ${(props) => props.color || '#aaa'};
    }

    i.up {
        animation: caret-up 1s infinite;
        @keyframes caret-up {
            0%,
            100% {
                transform: translateY(0);
            }
            50% {
                transform: translateY(-3px);
            }
        }
    }
    i.down {
        animation: caret-down 1s infinite;
        @keyframes caret-down {
            0%,
            100% {
                transform: translateY(0);
            }
            50% {
                transform: translateY(3px);
            }
        }
    }
`;
