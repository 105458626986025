import { AuditModule, AuditModuleCategory } from '@Types/Module';
import { getTextColor } from '@Utils/color.utils';
import { classNames } from 'primereact/utils';
import styled from 'styled-components';
import { ScoreProgress } from './components/ScoreProgress';

type Props = {
    cat: AuditModuleCategory;
    initialModuleScore: {
        score: {
            [theme: string]: number;
        };
        module: AuditModule;
    } | null;
    initialScore: number;
    score: number;
    comment: string;
    didImprove: boolean;
};
export const CategoryScore = (props: Props) => {

    return (
        <div className="flex flex-column gap-3 align-items-start">
            <div className="he-paragraph--regular" style={{ minWidth: 200 }}>
                <ThemeTag background={props.cat.color}>{props.cat.label}</ThemeTag>
            </div>
            <Card className={classNames('flex gap-2 w-full', 'flex-column align-items-center')}>
                <div className="flex flex-column justify-content-center align-items-center ">
                    <small className="roboto he-paragrah--xs gray-500">votre score </small>
                    <div className="flex align-items-center" style={{marginTop: -2}}>
                        <DisplayScore className="roboto gray-800">
                            {new Intl.NumberFormat('fr-FR', { maximumFractionDigits: 2 }).format(props.score * 100)}
                        </DisplayScore>
                        <Percent className="roboto">&nbsp;/100</Percent>
                    </div>
                </div>
                <ScoreProgress score={props.score} initialScore={props.initialScore} />
            </Card>
            <TextAreaDisplay color={props.cat.color}>{props.comment ?? 'Aucun commentaire trouvé'}</TextAreaDisplay>
        </div>
    );
};

const Card = styled.div`
    background-color: #fff;
    border-radius: 8px;
    border: 1px solid #e0e0e0;
    padding: 10px 10px 34px 10px;
    width: 100%;
`;

const ThemeTag = styled.div<{
    size?: number;
    background?: string;
    color?: string;
}>`
    text-align: center;
    padding: 6px 8px;
    border-radius: 6px;
    font-weight: bold;
    background: ${(props) => props.background || '#c8c8c8'};
    color: ${(props) => props.color || getTextColor(props.background || '#c8c8c8')};
    font-size: ${(props) => props.size || 14}px;
    line-height: ${(props) => (props.size ? props.size + 4 : 18)}px;
    font-family: 'roboto';
`;
const TextAreaDisplay = styled.div<{ color: string }>`
    line-height: 20px;
    font-size: 14px;
    font-family: 'roboto';
    font-weight: 400;
    white-space: pre-line;
    padding: 5px 10px 5px 10px;
    border-left: 4px solid ${(props) => props.color + '40'};
    background-color: ${(props) => props.color + '10'};
    width: 100%;
`;

const DisplayScore = styled.span`
    font-weight: 700;
    font-size: 24px;
    line-height: 130%;
`;

const Percent = styled.span`
    font-weight: 700;
    font-size: 10px;
    line-height: 130%;
    margin-top: 4px;
    color: #667085;
`;
