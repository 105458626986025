import { CustomDialog } from '@Components/CustomDialog';
import { Line } from '@Components/Line';
import { ModuleContext } from '@Context/Module.context';
import { SessionContext } from '@Context/Session.context';
import { SessionTrackerContext } from '@Context/SessionTracker.context';
import { useApi } from '@Hooks/api';
import { useBread } from '@Hooks/breadcrumbs';
import { useUser } from '@Hooks/firebase';
import { usePromise } from '@Hooks/promise';
import { useToast } from '@Hooks/toast';
import { SignModule } from '@Pages/Home/components/SignModule';
import { ModuleTopBar } from '@Pages/Home/Session/pages/Module/components/ModuleTopBar';
import { SessionEndDialog } from '@Pages/Home/Session/pages/Module/pages/components/SessionEndDialog';
import { UnitEndDialog } from '@Pages/Home/Session/pages/Module/pages/components/UnitEndDialog';
import { SessionExtended } from '@Types/Session';
import { Unit } from '@Types/Unit';
import { AuditRecord } from '@Types/User';
import { downloadUint8 } from '@Utils/download.utils';
import { AuditRecordStatus, durationToMillis, getRecordStatus } from '@Utils/module.utils';
import { canByPass } from '@Utils/participant.utils';
import { getBlobForResource } from '@Utils/resource.utils';
import { ResponsiveProps, useBreakpoint } from '@Utils/responsive.utils';
import _ from 'lodash';
import { Duration } from 'luxon';
import { Button } from 'primereact/button';
import { classNames } from 'primereact/utils';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { ResponsiveAuditSummary } from '../components/ResponsiveAuditSummary';
import { RecordLayoutProvider } from './RecordLayoutProvider';

const RecordPageLayout = styled.div<ResponsiveProps>`
    ${(props) => (!props.responsive.isDesktop ? 'position: relative;' : '')}
    display: grid;
    ${(props) =>
        props.responsive.isDesktop
            ? `
  grid-template-columns: 340px 50px minmax(0, 1fr);
  grid-template-rows: min-content min-content minmax(0, 1fr);
  grid-template-areas:
    "title title title"
    "actions actions actions"
    "summary step activity";
`
            : `
  grid-template-columns: minmax(0,1fr);
  grid-template-rows: min-content min-content min-content;
  grid-template-areas:
    "title"
    "summary"
    "activity"
    "actions"
`}
`;

const CustomStep = styled.div`
    background: #F1F7FD;
    grid-area: step;
`;

const SideSummary = styled.aside<ResponsiveProps>`
    width: ${(props) => (props.responsive.isDesktop ? '340px' : '100%')};
    padding: 28px 32px;
    grid-area: summary;
    ${(props) => (props.responsive.isDesktop ? 'border-right: 1px solid #eaecf0;' : 'border-top: 1px solid #eaecf0;')}
`;

const MainWrapper = styled.div<ResponsiveProps>`
    grid-area: activity;
    ${(props) => (props.responsive.isDesktop ? 'position: relative;' : '')}
    height: 100%;
    overflow: auto;
    background: white;
`;

const MainContent = styled.div<ResponsiveProps>`
    padding: 32px;
    max-width: 940px;
    ${(props) => (props.responsive.isDesktop ? '' : 'width: 100%;')}
`;

export const RecordLayout: React.FC<
    React.PropsWithChildren<{
        sideContent?: () => React.ReactElement;
    }>
> = (props) => {
    const { module: currentModule, records, modules, participant, unit } = useContext(ModuleContext);

    const {
        session,
        refresh,
        refreshModules: refreshModule,
        loadingModules: loadingModule,
    } = useContext(SessionContext);

    const { moduleTime, recordsTimeProgress, modulesProgress, loading: loadingTime, saveTime } = useContext(SessionTrackerContext);

    const responsive = useBreakpoint();

    const params = useParams<{
        session_id: string;
        module_id: string;
        record_id: string;
    }>();

    const bread = useBread();
    const navigate = useNavigate();
    const [congratulationVisible, setCongratulationVisible] = useState<boolean>(false);
    const [unitEndVisible, setUnitEndVisible] = useState<boolean>(false);
    const [signModuleVisible, setSignModuleVisible] = useState<boolean>(false);
    const [nextLoading, setNextLoading] = useState<boolean>(false);
    const [fullscreen, setFullscreen] = useState<boolean>(false);
    const [loadingNewRecord, setLoadingNewRecord] = useState<boolean>(false);
    const [visibleContinue, setVisibleContinue] = useState<boolean>(false);
    const [loadingSubmission, setLoadingSubmission] = useState<boolean>(false);
    const [loadingRemainingTime, setLoadingRemainingTime] = useState<boolean>(false);
    const [remainingTimeMessage, setRemainingTimeMessage] = useState<boolean>(false);

    const { error, success } = useToast('top-right', 3000);

    const contentRef = React.useRef<HTMLDivElement>(null);

    const { progress: userProgress, meta, allRecords } = useUser(true);

    useEffect(() => {
        const record_id = params.record_id;
        if (currentModule && record_id && records.length > 0) {
            const record = records.find((r) => r.record_id === record_id);
            if (record && record.module_id === currentModule.module_id) {
                bread.updateTree(() => {
                    return [
                        {
                            id: params.session_id,
                            url: `/session/${params.session_id}`,
                            label: session?.formation.title,
                        },
                        {
                            id: params.module_id,
                            url: `/session/${params.session_id}/module/${params.module_id}`,
                            label: currentModule.title,
                        },
                        {
                            label: record.meta.name,
                            id: record.record_id,
                            url: `/session/${params.session_id}/module/${params.module_id}/record/${record.record_id}`,
                        },
                    ];
                })
            }
        }
    }, [params.module_id, params.record_id, params.session_id, records, currentModule, session?.formation.title]);

    useEffect(() => {
        return document.addEventListener('fullscreenchange', (_) => {
            setFullscreen(!!document.fullscreenElement);
        });
    }, [contentRef]);

    const onFullscreen = () => {
        if (contentRef.current) {
            if (!fullscreen) {
                contentRef.current.requestFullscreen({
                    navigationUI: 'hide',
                });
            } else {
                document.exitFullscreen().catch((e) => {
                    console.error(e);
                });
            }
        }
    };

    const indexOfCurrentRecord = useMemo(() => {
        return records.findIndex((r) => r.record_id === params.record_id);
    }, [params.record_id, records]);

    const onContinue = useCallback(() => {
        if (!currentModule || !params.session_id || !params.record_id || !session) return Promise.resolve();
        saveTime();
        setNextLoading(true);
        return api.session_call_checkRecordTime({
            module_id: currentModule.module_id,
            record_id: params.record_id,
            session_id: params.session_id,
        })
            .then((response) => {
                if (response.result === 'ok') {
                    if (response.error === 'invalid') {
                        // reload page
                        window.location.reload();
                        return Promise.resolve();
                    }

                    return api
                        .session_call_addRecord({
                            module_id: currentModule.module_id,
                            session_id: params.session_id!,
                        })
                        .then((res) => {
                            if (res.result === 'ok') {
                                success('Dossier patient créé');

                                bread.updateTree(() => {
                                    return [
                                        {
                                            id: params.session_id,
                                            url: `/session/${params.session_id}`,
                                            label: session.formation.title,
                                        },
                                        {
                                            id: params.module_id,
                                            url: `/session/${params.session_id}/module/${params.module_id}`,
                                            label: currentModule.title,
                                        },
                                        {
                                            label: res.record.meta.name,
                                            id: res.record.record_id,
                                            url: `/session/${params.session_id}/module/${params.module_id}/record/${res.record.record_id}`,
                                        },
                                    ];
                                });

                                navigate(
                                    `/session/${params.session_id}/module/${params.module_id}/record/${res.record.record_id}`
                                );
                            } else {
                                error('Erreur lors de la création du dossier patient');
                            }
                        });
                } else {
                    error('Erreur lors de la vérification du dossier, veuillez réessayer');
                    return Promise.resolve();
                }
            })
            .catch((e) => {
                console.error(e);
                error('une erreur est survenue, veuillez réessayer');
            })
            .finally(() => {
                setNextLoading(false);
            });

    }, [currentModule, params.module_id, params.session_id, params.record_id, session]);

    const onNextRecord = useCallback(async () => {
        const record_id = params.record_id;
        if (!currentModule || !record_id || !session) return;

        const index = indexOfCurrentRecord;

        if (index + 1 >= records.length) return;

        const nextRecord = records[index + 1];

        bread.updateTree(() => {
            return [
                {
                    id: params.session_id,
                    url: `/session/${params.session_id}`,
                    label: session.formation.title,
                },
                {
                    id: params.module_id,
                    url: `/session/${params.session_id}/module/${params.module_id}`,
                    label: currentModule.title,
                },
                {
                    label: nextRecord.meta.name,
                    id: nextRecord.record_id,
                    url: `/session/${params.session_id}/module/${params.module_id}/record/${nextRecord.record_id}`,
                },
            ];
        })

        navigate(`/session/${params.session_id}/module/${params.module_id}/record/${nextRecord.record_id}`);



    }, [indexOfCurrentRecord, records, params.module_id, currentModule, params.record_id, params.session_id, session]);

    const hasSubmitted = useMemo(() => {
        if (!currentModule || currentModule.type !== 'audit') return false;
        return modulesProgress[currentModule.module_id];
    }, [currentModule, modulesProgress]);

    const remainingTime = useMemo(() => {
        setLoadingRemainingTime(true);
        if (currentModule?.minimal_duration == null && currentModule?.minimal_duration_unit == undefined) {
            console.log('noMinimalDuration', {
                currentModule,
                moduleTime,
            });

            setLoadingRemainingTime(false);
            return true;
        }

        const moduleProgress = modulesProgress[currentModule.module_id];

        if (moduleTime == null && moduleProgress === true) {
            setLoadingRemainingTime(false);
            return true;
        }

        if (
            moduleTime == null ||
            currentModule?.minimal_duration == null ||
            currentModule?.minimal_duration_unit == null
        ) {
            console.log('noModuleTimeOrDuration', {
                currentModule,
                moduleTime,
            });
            setLoadingRemainingTime(false);
            return false;
        }

        const minimumTimeInSeconds = durationToMillis(
            currentModule.minimal_duration,
            currentModule.minimal_duration_unit
        );

        setLoadingRemainingTime(false);
        return minimumTimeInSeconds - moduleTime;
    }, [moduleTime, currentModule, modulesProgress]);


    const remainingRecordTime = useMemo(() => {

        if (!currentModule || !records || records.length === 0 || currentModule.type !== "audit") return null;

        if (!currentModule.minimal_duration || !currentModule.minimal_duration_unit) return 0;

        const recordRequiredTime = durationToMillis(currentModule.minimal_duration, currentModule.minimal_duration_unit) / currentModule.min_records;

        const currentRecord = records.find(r => r.record_id === params.record_id);

        if (!currentRecord) return null;

        const recordProgress = recordsTimeProgress[currentRecord.record_id];

        if (!recordProgress) return recordRequiredTime;

        return Math.max(recordRequiredTime - recordProgress, 0);

    }, [currentModule, params.record_id, records, recordsTimeProgress]);

    const bypass = participant && session && canByPass(session, participant);
    const moduleIsCompleted = useMemo(() => {
        if (!currentModule || currentModule.type !== 'audit' || !session) return false;

        let recordRequiredTime = 0;
        if (!currentModule.minimal_duration || !currentModule.minimal_duration_unit) {
            recordRequiredTime = 0;
        } else {
            recordRequiredTime = durationToMillis(currentModule.minimal_duration, currentModule.minimal_duration_unit) / currentModule.min_records;
        }

        console.log("record min time", recordRequiredTime, recordsTimeProgress)

        const everyRecordsCompleted = records.every(r => {
            const recordProgress = recordsTimeProgress[r.record_id];
            if (!recordProgress) return false;

            const remainingTime = Math.max(recordRequiredTime - recordProgress, 0);

            console.log("record", r.record_id, remainingTime);

            let status = getRecordStatus({
                record: r,
                remainingTime,
                module: currentModule,
            });

            if (bypass)
                status |= AuditRecordStatus.TimeCompleted;

            return (status & (AuditRecordStatus.ActivitiesCompleted | AuditRecordStatus.TimeCompleted)) === (AuditRecordStatus.ActivitiesCompleted | AuditRecordStatus.TimeCompleted);
        });

        const min = currentModule.min_records;
        const max = currentModule.max_records;

        const initialModule = modules.find((mod) => mod.type === 'audit' && mod.audit_type === 'initial');
        const finalModule = modules.find((mod) => mod.type === 'audit' && mod.audit_type === 'final');

        if (finalModule && finalModule.module_id === currentModule.module_id && initialModule && allRecords) {
            const maxRecords = allRecords.filter(
                (r) => r.module_id === initialModule?.module_id && r.session_id === session.session_id
            ).length;

            return everyRecordsCompleted && records.length >= maxRecords;
        }

        return everyRecordsCompleted && records.length >= min && records.length <= max;
    }, [allRecords, currentModule, modules, records, recordsTimeProgress, session, params.record_id])

    const onSubmit = useCallback(async () => {
        console.log("onSubmit")
        const recordId = params.record_id;
        if (!currentModule || !recordId || !session || currentModule.type !== 'audit') return;

        if (!hasSubmitted && moduleIsCompleted) {
            saveTime();
            setLoadingSubmission(true);
            try {
                const res = await api.session_call_submitRecords({
                    session_id: session.session_id,
                    module_id: currentModule.module_id,
                });

                if (res.result === 'ok') {
                    success('Réponses soumises');
                }
            } catch (e) {
                console.error(e);
                error('Erreur lors de la soumission des réponses');
            } finally {
                setLoadingSubmission(false);
            }
        }

        const config = participant?.unitsMeta?.find((u) => u.unit_id === unit?.unit_id);
        const unitConfig = session.unitsConfig.find((u) => u.unit_id === unit?.unit_id);

        // if already signed or signature not required
        if (config?.signature_id || (unitConfig && !unitConfig.required_signature)) {
            const unitIndex = session.formation.units.findIndex((u) => u.unit_id === unit?.unit_id);
            const isLast = session.formation.units.length - 1 === unitIndex;

            if (isLast && !unitConfig?.required_signature) {
                setNextLoading(true);
                api.participant_call_giveAccess({
                    session_id: session.session_id,
                }).then(() => {
                    setCongratulationVisible(true);
                });
                return;
            }

            refresh();
            navigate(`/session/${params.session_id}`);
            bread.updateTree((prev) => [
                {
                    id: params.session_id,
                    url: `/session/${params.session_id}`,
                    label: session.formation.title,
                },
            ]);
        } else {
            setUnitEndVisible(true);
        }

        return;
    }, [params.record_id, params.session_id, currentModule, session, moduleIsCompleted, hasSubmitted, participant?.unitsMeta, unit?.unit_id]);

    const onDefaultNext = useCallback(() => {
        if (!hasSubmitted)
            saveTime();

        const recordId = params.record_id;

        if (!currentModule || !recordId || !session || currentModule.type !== 'audit' || !allRecords) return;

        let min = currentModule.min_records;
        let max = currentModule.max_records;

        const initialModule = modules.find((m) => m.type === 'audit' && m.audit_type === 'initial');
        const finalModule = modules.find((m) => m.type === 'audit' && m.audit_type === 'final');

        if (finalModule && finalModule.module_id === currentModule.module_id && initialModule && allRecords) {
            max = allRecords.filter(
                (r) => r.module_id === initialModule?.module_id && r.session_id === session.session_id
            ).length;
            min = max;
        }

        const index = indexOfCurrentRecord;

        if (index === records.length - 1) {
            if (hasSubmitted) {
                bread.updateTree((prev) => [
                    {
                        id: params.session_id,
                        url: `/session/${params.session_id}`,
                        label: session.formation.title,
                    },
                    {
                        id: params.module_id,
                        url: `/session/${params.session_id}/module/${params.module_id}`,
                        label: currentModule.title,
                    },
                ]);
                navigate(`/session/${params.session_id}/module/${params.module_id}`);
                return;
            }
            if (records.length < min) {
                onContinue();
            } else if (records.length >= min && records.length <= max && moduleIsCompleted) {
                setVisibleContinue(true);
            }
        } else {
            onNextRecord();
        }
    }, [
        params.record_id,
        params.module_id,
        params.session_id,
        currentModule,
        indexOfCurrentRecord,
        records,
        session,
        participant,
        hasSubmitted,
        unit,
        modules,
        moduleIsCompleted,
        allRecords,
        onContinue,
        onNextRecord,
    ]);

    const onPrevious = () => {
        const record_id = params.record_id;
        if (!currentModule || !record_id || !session) return;

        const index = records.findIndex((r) => r.record_id === record_id);

        if (index - 1 < 0) return;

        const previousRecord = records[index - 1];

        bread.updateTree(() => [
            {
                id: params.session_id,
                url: `/session/${params.session_id}`,
                label: session.formation.title,
            },
            {
                id: params.module_id,
                url: `/session/${params.session_id}/module/${params.module_id}`,
                label: currentModule.title,
            },
            {
            label: previousRecord.meta.name,
            id: previousRecord.record_id,
            url: `/session/${params.session_id}/module/${params.module_id}/record/${previousRecord.record_id}`,
            }
        ])

        navigate(`/session/${params.session_id}/module/${params.module_id}/record/${previousRecord.record_id}`);

    };

    const progress = useMemo(() => {
        if (!currentModule || currentModule.type !== 'audit' || !params.session_id) return 0;

        const totalMaxRecords = Math.min(
            Math.max(currentModule.min_records, records.length),
            currentModule.max_records
        );
        const totalActivities = currentModule.activities.length * totalMaxRecords;

        const completedActivities = records.reduce((acc, record) => {
            return acc + record.answers.length;
        }, 0);

        return (100 * completedActivities) / totalActivities;
    }, [currentModule, params.session_id, records]);

    useEffect(() => {
        refreshModule(session);
    }, [session]);

    const onQuit = () => {
        if (session) {
            bread.updateTree((prev) => [
                {
                    id: params.session_id,
                    url: `/session/${params.session_id}`,
                    label: session.formation.title,
                },
                {
                    id: params.module_id,
                    url: `/session/${params.session_id}/module/${params.module_id}`,
                },
            ]);
            refresh();
            navigate(`/session/${params.session_id}/module/${params.module_id}`);
        }
    };

    const completedRecords = useMemo(() => {
        if (records.length === 0 || !params.session_id || !params.module_id) return [];
        return records
            .filter((r) => {
                return currentModule?.activities.length === r.answers.length;
            })
            .map((r) => r.record_id);
    }, [currentModule?.activities.length, params.module_id, params.session_id, records]);

    const startedRecords = useMemo(() => {
        if (records.length === 0 || !params.session_id || !params.module_id) return [];
        return records.map((r) => r.record_id);
    }, [params.module_id, params.session_id, records]);

    const [downloadFile, loading] = usePromise(async () => {
        if (!session) return;
        const [res, blob] = await getBlobForResource(session.formation.educational_support_url);

        if (!res?.resource_url || !blob) return;
        const a = document.createElement('a');

        // download file from url using fetch
        const file = new File([blob], res.resource_name, { type: 'application/data' });
        const url = window.URL.createObjectURL(file);
        a.href = url;
        a.download = res.resource_name;
        a.click();
    });

    const onChangeRecord = useCallback(
        (record: AuditRecord) => {
            const recordId = params.record_id;

            if (!recordId) return;

            bread.removeById(recordId);

            navigate(`/session/${params.session_id}/module/${params.module_id}/record/${record.record_id}`);

            if (session) {
                bread.updateTree(() => [
                    {
                        id: params.session_id,
                        url: `/session/${params.session_id}`,
                        label: session.formation.title,
                    },
                    {
                        id: params.module_id,
                        url: `/session/${params.session_id}/module/${params.module_id}`,
                        label: currentModule?.title,
                    },
                    {
                        id: record.record_id,
                        url: `/session/${params.session_id}/module/${params.module_id}/record/${record.record_id}`,
                        label: record.meta.name,
                    },
                ]);
            }
        },
        [bread, currentModule?.title, params.module_id, params.record_id, params.session_id, session]
    );

    const api = useApi();

    const [onDownloadDPC, dpcLoading] = usePromise(async () => {
        if (session && !participant?.manual_attestation_lock) {
            const res = await api.session_call_getAttestation({
                queries: [
                    {
                        session_id: session.session_id,
                        user_ids: [meta.user_id],
                    },
                ],
            });

            if (res.result !== 'ok') throw new Error(res.result);

            if (res.mode === 'single') {
                await downloadUint8(
                    res.file,
                    `Attestation suivi formation - ${session.formation.andpc} - Session ${session.session_custom_id} - ${meta.lastname} ${meta.firstname}.pdf`,
                    'application/pdf'
                );
            }
        }
    });

    const onUnitSigned = (unit: Unit, session: SessionExtended<false>) => {
        setSignModuleVisible(false);

        const isLastUnit = _.last(session.formation.units)?.unit_id === unit.unit_id;

        const unitindex = session.formation.units.findIndex((u) => u.unit_id === unit.unit_id);

        if (isLastUnit) {
            setCongratulationVisible(true);
            return;
        }

        const nextUnit = session.formation.units[unitindex + 1];
        const config = session.unitsConfig.find((u) => u.unit_id === nextUnit.unit_id);

        if (!config) return;

        if (config.start_date) {
            bread.updateTree((prev) => [
                {
                    id: params.session_id,
                    url: `/session/${params.session_id}`,
                    label: session.formation.title,
                },
            ]);
            refresh();
            navigate(`/session/${params.session_id}`);
        } else {
            const nextModule = modules.find((m) => m.module_id === nextUnit.modules_ids[0]);
            if (!nextModule) return;
            navigate(`/session/${params.session_id}/module/${nextModule.module_id}`);
            bread.updateTree((prev) => [
                {
                    id: params.session_id,
                    url: `/session/${params.session_id}`,
                    label: session.formation.title,
                },
                {
                    id: nextModule.module_id,
                    url: `/session/${params.session_id}/module/${nextModule.module_id}`,
                    label: nextModule.title,
                },
            ]);
        }
    };

    const [showConfirmDownload, setShowConfirmDownload] = useState<boolean>(false);


    const recordStatus = useMemo(() => {
        const currentRecord = records.find((r) => r.record_id === params.record_id);
        if (!currentRecord || !currentModule || currentModule.type !== "audit") return null;

        let recordStatus = getRecordStatus({
            record: currentRecord,
            module: currentModule,
            remainingTime: remainingRecordTime ?? -1
        });

        if (bypass) {
            recordStatus |= AuditRecordStatus.TimeCompleted;
        }

        return recordStatus;

    }, [records, currentModule, remainingRecordTime, bypass, params.record_id]);

    const onNewRecord = useCallback(async () => {
        if (!params.session_id || !currentModule) return;

        setLoadingNewRecord(true);
        try {
            const res = await api.session_call_addRecord({
                module_id: currentModule.module_id,
                session_id: params.session_id,
            });

            if (res.result === 'ok') {
                success('Dossier patient créé');
            } else {
                throw new Error(res.result);
            }
        } catch (e) {
            console.error(e);
            error('Erreur lors de la création du dossier patient');
        } finally {
            setLoadingNewRecord(false);
        }
    }, [currentModule, params.session_id]);


    const recordsCards = useMemo<React.ReactElement[]>(() => {
        if (!currentModule || currentModule.type !== 'audit' || !session) return [<></>];

        const template = (record: AuditRecord | null, index: number, enabled=false) => {
            return (
                <div
                    key={record?.record_id ?? `record-${index}`}
                    className={classNames(
                        'ml-3 mt-3 he-paragraph--regular',
                        !enabled ? 'cursor-default opacity-50' : 'cursor-pointer',
                        record
                            ? params.record_id === record.record_id
                                ? completedRecords.includes(record.record_id)
                                    ? 'color-light-green cursor-pointer'
                                    : 'primary-100 cursor-pointer'
                                : completedRecords.includes(record.record_id)
                                ? 'color-teal cursor-pointer'
                                : startedRecords.includes(record.record_id)
                                ? 'secondary-100 cursor-pointer'
                                : 'gray-400 cursor-pointer'
                            : 'gray-400 cursor-pointer'
                    )}
                    onClick={() => {
                        if (!enabled) return;
                        if (record) onChangeRecord(record);
                        else onNewRecord();
                    }}
                >
                    <i
                        className={classNames(
                            loadingNewRecord
                                ? 'pi pi-spin pi-spinner'
                                : record && startedRecords.includes(record.record_id)
                                ? completedRecords.includes(record.record_id)
                                    ? 'pi pi-check-circle'
                                    : 'pi pi-folder-open'
                                : 'pi pi-plus-circle',
                            'mr-2'
                        )}
                    />
                    {record?.meta.name ?? `Nouveau dossier`}
                </div>
            );
        };

        let recordElems: React.ReactElement[] = records.map((r) => {
            return template(r, records.indexOf(r), true);
        });

        const nextIndex = records.length;

        const hasCompletedModule = recordStatus && (recordStatus & (AuditRecordStatus.ActivitiesCompleted | AuditRecordStatus.TimeCompleted)) === (AuditRecordStatus.ActivitiesCompleted | AuditRecordStatus.TimeCompleted);

        for (let i = records.length; i < currentModule.min_records; i++) {
            if (hasCompletedModule && i === nextIndex) {
                recordElems.push(template(null, i, true));
            } else {
                recordElems.push(template(null, i));
            }
        }

        const finalModule = modules.find((m) => m.type === 'audit' && m.audit_type === 'final');
        const initialModule = modules.find((m) => m.type === 'audit' && m.audit_type === 'initial');

        let min = currentModule.min_records;
        let max = currentModule.max_records;

        if (finalModule && finalModule.module_id === currentModule.module_id && initialModule && allRecords) {
            max = allRecords.filter(
                (r) => r.module_id === initialModule?.module_id && r.session_id === session.session_id
            ).length;
            min = max;
        }
        if (records.length >= min && records.length < max && !hasSubmitted) {
            recordElems.push(template(null, records.length));
        }

        return recordElems;
    }, [
        currentModule,
        records,
        modules,
        allRecords,
        hasSubmitted,
        params.record_id,
        completedRecords,
        startedRecords,
        loadingNewRecord,
        onChangeRecord,
        onNewRecord,
    ]);

    const mobileWrapper = (children?: React.ReactElement | boolean) => {
        return (
            <div className="flex w-full h-full">
                <div className="h-full" style={{ width: 40 }}>
                    {!responsive.isDesktop && <CustomStep className="RecordActivitySide h-full" />}
                </div>
                <div className="h-full w-full overflow-auto">{children ? children : <></>}</div>
            </div>
        );
    };

    const canCreateNewRecord = useMemo(() => {
        if (!currentModule || currentModule.type !== 'audit' || !session) return false;
        let max = currentModule.max_records;

        const initialModule = modules.find((m) => m.type === 'audit' && m.audit_type === 'initial');
        const finalModule = modules.find((m) => m.type === 'audit' && m.audit_type === 'final');

        if (finalModule && finalModule.module_id === currentModule.module_id && initialModule && allRecords) {
            max = allRecords.filter(
                (r) => r.module_id === initialModule?.module_id && r.session_id === session.session_id
            ).length;
        }

        return records.length < max;
    }, [currentModule, session, modules, allRecords, records.length]);

    const canContinue = useMemo(() => {
        if (!currentModule || currentModule.type !== 'audit') return false;
        return moduleIsCompleted || canCreateNewRecord;
    }, [currentModule, moduleIsCompleted, canCreateNewRecord]);

    const nextTextLabel = useMemo(() => {
        if (!currentModule || currentModule.type !== 'audit') return '';
        console.log('nextTextLabel', {
            moduleIsCompleted,
            hasSubmitted,
            remainingRecordTime
        })
        return hasSubmitted
            ? 'Dossier suivant'
            : moduleIsCompleted
            ? 'Soumettre'
            : remainingRecordTime !== null && remainingRecordTime > 0
            ? Duration.fromMillis(remainingRecordTime).toFormat('hh:mm:ss')
            : 'Dossier suivant';

    }, [currentModule, hasSubmitted, moduleIsCompleted, remainingRecordTime])

    const nextDisabled = useMemo(() => {
        const conditions = [
            nextLoading ,
            (indexOfCurrentRecord === records.length - 1 && !canContinue) ,
            loadingSubmission ,
            loadingRemainingTime ,
            (!hasSubmitted && !!recordStatus && (recordStatus & (AuditRecordStatus.ActivitiesCompleted | AuditRecordStatus.TimeCompleted)) !== (AuditRecordStatus.ActivitiesCompleted | AuditRecordStatus.TimeCompleted))
            ];
        return conditions.some((c) => c);
    }, [canContinue, hasSubmitted, indexOfCurrentRecord, loadingRemainingTime, loadingSubmission, nextLoading, recordStatus, records.length]);

    const nextClick = useCallback(() => {
        if (nextDisabled) return;
        
        if (!currentModule || currentModule.type !== 'audit') return;

        if (moduleIsCompleted && typeof remainingTime === "number" && remainingTime > 0 && !bypass) {
            setRemainingTimeMessage(true);
            return;
        }

        if (moduleIsCompleted && remainingRecordTime !== null && remainingRecordTime > 0 && !bypass) {
            setRemainingTimeMessage(true);
            return;
        }

        onDefaultNext();

    }, [bypass, currentModule, moduleIsCompleted, nextDisabled, onDefaultNext, remainingRecordTime])

    const baseContent = currentModule && (
        <RecordPageLayout responsive={responsive} className="RecordPageLayout w-full h-full" ref={contentRef}>
            {session && participant && (
                <CustomDialog visible={congratulationVisible} onHide={() => setCongratulationVisible(false)}>
                    <SessionEndDialog
                        module={currentModule}
                        session={session}
                        dpcLoading={dpcLoading}
                        onDownloadDPC={onDownloadDPC}
                        participant={participant}
                    />
                </CustomDialog>
            )}
            {session && (
                <CustomDialog visible={unitEndVisible} onHide={() => setUnitEndVisible(false)}>
                    <UnitEndDialog session={session} onSign={() => setSignModuleVisible(true)} />
                </CustomDialog>
            )}
            {session && unit && (
                <CustomDialog
                    visible={signModuleVisible}
                    onHide={() => setSignModuleVisible(false)}
                    width={responsive.isDesktop || responsive.small ? 950 : responsive.width}
                >
                    <SignModule
                        session={session}
                        onCancel={() => setSignModuleVisible(false)}
                        unit={unit}
                        onSigned={() => onUnitSigned(unit, session)}
                    />
                </CustomDialog>
            )}
            {showConfirmDownload && (
                <CustomDialog visible onHide={() => setShowConfirmDownload(false)} width={400}>
                    <div className="he-header--h2 mb-3 text-center">Téléchargement du support pédagogique</div>
                    <div className="he-paragraph--regular-16 gray-500 mb-4 text-center">
                        Ce support pédagogique a pour seul objectif de faciliter la compréhension et l’apprentissage des
                        vidéos suivantes, sa lecture ne sera pas prise en compte dans le suivi de cette formation.{' '}
                        <br />
                        <br /> Pour suivre la formation, cliquez sur "Formation"
                    </div>
                    <div className="flex flex-column gap-2 w-full">
                        <Button
                            className="he-button--primary--md"
                            onClick={downloadFile}
                            loading={loading}
                            disabled={loading}
                        >
                            <i className="pi pi-download" />
                            Télécharger le support
                        </Button>
                        <Button
                            className="he-button--secondary-variant--md"
                            onClick={() => setShowConfirmDownload(false)}
                        >
                            Formation
                        </Button>
                    </div>
                </CustomDialog>
            )}
            {visibleContinue && (
                <CustomDialog visible onHide={() => setVisibleContinue(false)} width={400} closable>
                    <div className="he-header--h2 mb-3 text-center"></div>
                    <div className="he-paragraph--regular-16 gray-500 mb-4 text-center">
                        Vous avez complété le nombre requis de dossiers patients pour cette formation.
                        <br />
                        {moduleIsCompleted ? (
                            <>
                                <br /> Vous pouvez soumettre vos réponses pour terminer la formation.
                                <br />
                                <br /> <span className="he-paragraph--regular text-red-500"><i className="mr-2 pi pi-info-circle" /> Attention une fois vos dossiers soumis, vous ne pourrez plus y apporter de changement</span>
                            </>
                        ) : (
                            <>
                                <br /> Vous pouvez créer un nouveau dossier patient ou finaliser vos dossiers patients
                                commencé pour soumettre vos réponses
                            </>
                        )}
                    </div>

                    <div className="flex flex-column gap-2 w-full">
                        {canCreateNewRecord && (
                            <Button
                                className="he-button--primary--md"
                                onClick={() => {
                                    onContinue().finally(() => {
                                        setVisibleContinue(false);
                                    });
                                }}
                            >
                                Nouveau dossier patient
                            </Button>
                        )}
                        {moduleIsCompleted ? (
                            <Button
                                className="he-button--warning--md"
                                onClick={() => onSubmit()}
                                loading={loadingSubmission}
                                disabled={loadingSubmission}
                            >
                                Soumettre mes réponses
                            </Button>
                        ) : (
                            <Button
                                className="he-button--secondary-variant--md"
                                onClick={() => setVisibleContinue(false)}
                                loading={loadingSubmission}
                                disabled={loadingSubmission}
                            >
                                Compléter mes réponses manquantes
                            </Button>
                        )}
                        <Button 
                            className="he-button--secondary-variant-nf--md"
                            label="Annuler"
                            onClick={() => setVisibleContinue(false)}
                        />
                    </div>
                </CustomDialog>
            )}
            <ModuleTopBar
                module={currentModule}
                progress={progress}
                fullscreen={fullscreen}
                onFullscreen={onFullscreen}
                remainingTime={remainingTime}
                style={{ borderBottom: '1px solid #EAECF0', gridArea: 'title' }}
            />
            <div
                className={classNames(
                    responsive.isDesktop ? 'px-6 ' : 'px-3',
                    'flex justify-content-start align-items-center py-2 bg-white'
                )}
                style={{
                    [responsive.isDesktop ? 'borderBottom' : 'borderTop']: '1px solid #EAECF0',
                    gridArea: 'actions',
                }}
            >
                {responsive.isDesktop && (
                    <div className="flex gap-3 align-items-center">
                        <div className="he-paragraph--regular gray-500 cursor-pointer">
                            <span className="cursor-pointer" onClick={onQuit}>
                                <i className="pi pi-sign-out mr-2" />
                                Revenir à la liste des dossiers patients
                            </span>
                        </div>
                    </div>
                )}
                {typeof remainingTime === 'number' && remainingTime > 0 && (
                    <CustomDialog
                        visible={remainingTimeMessage}
                        onHide={() => setRemainingTimeMessage(false)}
                        width={600}
                    >
                        <div className="text-center he-header--h3">
                            <p>
                                Vous ne pouvez pas accéder au module suivant car le temps minimum requis pour le module
                                en cours n'est pas encore atteint.
                            </p>
                            <p className="mt-2">
                                Il sera disponible dans {Duration.fromMillis(remainingTime).toFormat('hh:mm:ss')}
                            </p>
                        </div>
                        <div className="flex justify-content-center mt-3">
                            <Button
                                className="he-button--secondary-nfb--xs"
                                onClick={() => setRemainingTimeMessage(false)}
                            >
                                Fermer
                            </Button>
                        </div>
                    </CustomDialog>
                )}
                <div className={`flex ml-auto ${!responsive.isDesktop ? 'justify-content-around gap-2 w-full px-5' : 'gap-2'}`}>
                    <Button
                        label="Précédent"
                        disabled={indexOfCurrentRecord <= 0}
                        className="he-button--primary-nf--xs w-max"
                        onClick={onPrevious}
                        icon="pi pi-arrow-circle-left"
                    />
                    <Button
                        label={
                            nextTextLabel
                        }
                        loading={
                            nextLoading ||
                            loadingModule ||
                            loading ||
                            loadingTime ||
                            loadingSubmission ||
                            loadingRemainingTime
                        }
                        icon="pi pi-arrow-circle-right"
                        className="he-button--primary--xs w-max"
                        disabled={nextDisabled}
                        onClick={nextClick}
                        iconPos="right"
                    />
                </div>
            </div>
            {responsive.isDesktop ? (
                <SideSummary responsive={responsive} className="h-full bg-white flex flex-column flex-grow-0">
                    <div className="he-header--h2 gray-900 flex-grow-0">Dossiers patients</div>
                    <div className="flex-grow-1 overflow-auto mb-3">{recordsCards}</div>
                    <div className="flex-grow-0">
                        <Button
                            className="w-full he-button--secondary-nf--xs--rounded"
                            onClick={() => setShowConfirmDownload(true)}
                            loading={loading}
                            disabled={!session?.formation.educational_support_url}
                        >
                            <i className="pi pi-download" />
                            {!session?.formation.educational_support_url
                                ? 'Aucun support pédagogique'
                                : 'Télécharger le support pédagogique'}
                        </Button>
                        <Line height={1} style={{ background: '#d8ebfb' }} className="my-3" />
                        <div className="w-full flex justify-content-between mt-3">
                            <a
                                className="w-max text-center he-paragraph--small--bold cursor-pointer no-underline"
                                style={{ color: 'rgba(11,85,153,0.58)' }}
                                href={'mailto:contact@healthevents.fr'}
                            >
                                <i className="pi pi-envelope mr-2" />
                                Contacter l'animateur
                            </a>
                            <a
                                className="w-max text-center he-paragraph--small--bold cursor-pointer no-underline"
                                style={{ color: 'rgba(11,85,153,0.58)' }}
                                href={'tel:0176431249'}
                            >
                                <i className="pi pi-phone mr-2" />
                                01 76 43 12 49
                            </a>
                        </div>
                    </div>
                </SideSummary>
            ) : (
                <ResponsiveAuditSummary
                    loading={loading}
                    recordCards={(): React.ReactElement[] => recordsCards}
                    downloadFile={downloadFile}
                    onQuit={onQuit}
                />
            )}
            <CustomStep className="RecordActivitySide" />
            <MainWrapper className="RecordScrollContainer" responsive={responsive}>
                <MainContent responsive={responsive} className="flex-1">
                    {!userProgress ? <i className="pi pi-spin pi-spinner" /> : props.children}
                </MainContent>
            </MainWrapper>
        </RecordPageLayout>
    );

    return currentModule && params.record_id && indexOfCurrentRecord !== -1 ? (
        <RecordLayoutProvider module={currentModule} record={records[indexOfCurrentRecord]}>
            {responsive.isDesktop ? baseContent : mobileWrapper(baseContent)}
        </RecordLayoutProvider>
    ) : (
        <div className="w-full h-full flex justify-content-center align-items-center">
            <i className="pi pi-spin pi-spinner gray-300" style={{ fontSize: '3em' }} />
        </div>
    );
};
