import { NavigationContext } from '@Context/Navigation.context';
import { SessionTrackerContext } from '@Context/SessionTracker.context';
import { useApi } from '@Hooks/api';
import { useUser } from '@Hooks/firebase';
import { usePromise } from '@Hooks/promise';
import { useToast } from '@Hooks/toast';
import style from '@Pages/Home/components/SessionCard.module.scss';
import { Module } from '@Types/Module';
import { SessionExtended, SessionParticipant } from '@Types/Session';
import { UnitConfig } from '@Types/Unit';
import { isLess } from '@Utils/date.utils';
import { getLastActivity, isSessionStarted } from '@Utils/progress.utils';
import { getUrlForResource } from '@Utils/resource.utils';
import { DateTime, Interval } from 'luxon';
import { Button } from 'primereact/button';
import { ProgressBar } from 'primereact/progressbar';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const CardWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    border-radius: 14px;
    border: 1px solid #eaecf0;
    overflow: hidden;
    max-width: 358px;
`;

export type SessionCardProps = {
    session: SessionExtended<false>;
    className?: string;
    isFinished?: true;
    loading?: boolean;
    participant: SessionParticipant;
};

const CustomButton = styled.a`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 10px;
    background: #ebf4fc;
    border: 1px solid #cee3f8;
    border-radius: 8px;
`;
export const SessionCard: React.FC<SessionCardProps> = (props) => {
    const { progress, allRecords } = useUser(true);
    const api = useApi();
    const [image, setImage] = useState<string>();

    useEffect(() => {
        if (props.session.formation.cover_image_url) {
            getUrlForResource(props.session.formation.cover_image_url).then((res) => {
                setImage(res?.resource_url);
            });
        }
    }, [props.session.formation.cover_image_url]);

    const { timeSinceDownload, report, forceLoadSessionReport } = useContext(SessionTrackerContext);

    const [modules, setModules] = useState<Module[]>([]);

    const modulesProgress = useMemo<
        Array<{
            completed: number;
            last_activity?: string;
            last_record?: string;
            module_id: string;
        }>
    >(() => {
        const sorted_modules = props.session.formation.units
            .map((u) => u.modules_ids.map((mid) => modules.find((m) => m.module_id === mid)))
            .flat()
            .filter((m) => !!m);

        return sorted_modules.map((m) => {
            if (m.type !== 'audit' && progress) {
                const moduleDoneActivities = progress.filter(
                    (p) => p.module_id === m.module_id && p.session_id === props.session.session_id && p.activity.done
                );

                const moduleProgression = moduleDoneActivities.length / m!.activities.length;

                return {
                    module_id: m!.module_id,
                    completed: moduleProgression * 100,
                    last_activity: moduleDoneActivities[moduleDoneActivities.length - 1]?.activity_id,
                };
            } else if (allRecords && m.type === 'audit') {
                const records = allRecords.filter(
                    (r) => r.module_id === m.module_id && r.session_id === props.session.session_id
                );

                const totalMaxRecords = Math.min(Math.max(m.min_records, records.length), m.max_records);
                const totalActivities = m.activities.length * totalMaxRecords;

                const completedActivities = records.reduce((acc, record) => {
                    return acc + record.answers.length;
                }, 0);

                const moduleProgress = completedActivities / totalActivities;

                return {
                    module_id: m.module_id,
                    completed: moduleProgress * 100,
                    last_record: records[records.length - 1]?.record_id,
                };
            }

            return {
                module_id: m.module_id,
                completed: 0,
            };
        });
    }, [modules, progress, props.session.formation.units, props.session.session_id]);

    const { updateTree } = useContext(NavigationContext);

    const { error } = useToast();

    const [loadModules, loading] = usePromise(async () => {
        const res = await api.formation_call_modules({ formation_id: props.session.formation_id });
        if (res.result !== 'ok') throw new Error(res.result);
        setModules(res.modules);
    });

    const navigate = useNavigate();

    const onStart = () => {
        navigate(`/session/${props.session.session_id}`, {
            state: props.session,
        });
    };

    const [loadingReport, setLoadingReport] = useState<boolean>(false);

    const onContinue = async () => {
        let _report = report;

        if (!report || report.session_id !== props.session.session_id) {
            setLoadingReport(true);
            _report = await forceLoadSessionReport(props.session.session_id);
            setLoadingReport(false);
        }

        if (!allRecords || !progress || !_report) return;

        const res = getLastActivity(
            allRecords,
            progress,
            props.session,
            modules,
            props.participant,
            _report,
            timeSinceDownload ?? Date.now(),
            (err, url) => {
                error(err);
                if (url) navigate(url);
            }
        );

        if (res && !res.module_id) {
            error('Une erreur est survenue lors de la récupération de la dernière activité');
            return;
        }


        if (res && res.module_id && res.activity_id === undefined) {
            updateTree(() => {
                return [
                    {
                        id: props.session.session_id,
                        url: `/session/${props.session.session_id}?u=${props.session.formation.units.length - 1}`,
                        label: props.session.formation.title,
                    },
                    {
                        id: res.module_id,
                        url: `/session/${props.session.session_id}/module/${res.module_id}`,
                        label: modules.find((m) => m.module_id === res.module_id)?.title,
                    },
                ];
            })
            navigate(`/session/${props.session.session_id}/module/${res.module_id}`);
        }

        if (!res) return;

        const sessionUrl = `/session/${props.session.session_id}?u=${props.session.formation.units.length - 1}`;

        if (res.finished) {
            updateTree((prev) => {
                return [
                    {
                        id: props.session.session_id,
                        url: sessionUrl,
                        label: props.session.formation.title,
                    },
                ];
            });
            navigate(sessionUrl);
            return;
        }

        const module = modules.find((m) => m.module_id === res.module_id);
        if (!module) return;

        const moduleUrl = `${sessionUrl}/module/${res.module_id}`;

        const activity = module.activities.find((a) => a.activity_id === res.activity_id);

        if (!activity) return;

        const activityUrl = `${moduleUrl}/activity/${res.activity_id}`;

        updateTree(() => {
            return [
                {
                    id: props.session.session_id,
                    url: sessionUrl,
                    label: props.session.formation.title,
                },
                {
                    url: moduleUrl,
                    id: module.module_id,
                    label: module.title,
                },
                {
                    url: activityUrl,
                    id: activity.activity_id,
                    label: activity.title,
                },
            ];
        });
        navigate(`/session/${props.session.session_id}/module/${res.module_id}/activity/${res.activity_id}`);
    };

    useEffect(() => {
        loadModules();
    }, [props.session]);

    const currentUnitMeta = useMemo<UnitConfig | null>(() => {
        const units = props.session.unitsConfig;
        let i = 0;
        let currentUnit = null;
        for (const unit of units) {
            if (!unit.start_date) {
                i++;
                continue;
            }
            const currentStartDiff = DateTime.now().diff(DateTime.fromISO(unit.start_date));
            const nextUnit = units[i + 1];
            if (i + 1 === units.length) {
                if (currentStartDiff.as('seconds') >= 0) {
                    currentUnit = unit;
                }
            } else {
                if (nextUnit?.start_date) {
                    const nextStartDiff = DateTime.now().diff(DateTime.fromISO(nextUnit.start_date));
                    if (currentStartDiff.as('seconds') >= 0 && nextStartDiff.as('seconds') <= 0) {
                        currentUnit = unit;
                    }
                }
            }
            i++;
        }
        return currentUnit;
    }, [props.session]);

    const getNextUnitText = () => {
        const units = props.session.unitsConfig;

        const today = DateTime.now();

        const matchingUnit = units.filter((unit) => {
            if (!unit.start_date || !unit.end_date) return false;
            const interval = Interval.fromDateTimes(DateTime.fromISO(unit.start_date), DateTime.fromISO(unit.end_date));
            return interval.contains(today);
        });

        if (matchingUnit.length > 0) return 'Reprendre la formation';

        const nextUnit = units.find((unit) => {
            if (!unit.start_date) return false;
            return DateTime.fromISO(unit.start_date).diff(today).as('seconds') > 0;
        });

        if (!nextUnit) return 'Reprendre la formation';

        return (
            <div>
                La formation reprendra le <br />
                {DateTime.fromISO(nextUnit.start_date!).setLocale('FR-fr').toFormat('dd LLLL yyyy')}
            </div>
        );
    };

    return (
        <CardWrapper className={props.isFinished ? 'bg-gray-200' : 'bg-white'}>
            <div
                className="bg-gray-300 flex justify-content-center align-items-center"
                style={{ height: '200px', width: '364px', overflow: 'hidden' }}
            >
                {image && (
                    <img
                        src={image}
                        style={{ width: '364px', height: 'auto' }}
                        alt={`Formation : ${props.session.formation.title}`}
                    />
                )}
            </div>
            <div className="p-4 flex-1 flex flex-column justify-content-start align-items-center">
                <div
                    className="he-header--h3 gray-800 mb-2 cursor-pointer hover:underline"
                    onClick={() =>
                        !props.participant.blocked &&
                        !props.isFinished &&
                        isLess(props.session.start_date, DateTime.now()) &&
                        navigate(`/session/${props.session.session_id}`)
                    }
                >
                    {props.session.formation.title.length >= 110
                        ? props.session.formation.title.substring(0, 110) + '...'
                        : props.session.formation.title}
                </div>
                {props.participant.blocked ? (
                    <div className="flex flex-column align-items-center">
                        <div className="he-paragraph--regular gray-700 text-center mt-2">
                            Vous n’avez plus accès à cette formation, si vous avez des questions appelez l’animateur
                        </div>
                        <CustomButton
                            className="block he-paragraph--small secondary-100 mt-4 no-underline w-max"
                            href="mailto:contact@healthevents.fr"
                        >
                            <i className="pi pi-envelope mr-2" />
                            Contacter l'animateur
                        </CustomButton>

                        <CustomButton
                            className="block he-paragraph--small secondary-100 mt-3 no-underline w-max"
                            href="tel:0176431249"
                        >
                            <i className="pi pi-phone mr-2" />
                            01 76 43 12 49
                        </CustomButton>
                    </div>
                ) : (
                    <>
                        <div className="mb-3 w-full mt-auto">
                            {loading ? (
                                <i className="pi pi-spin pi-spinner" />
                            ) : (
                                <>
                                    <div className="he-paragraph--medium gray-400 mb-2">Progression</div>
                                    <div className={style.progressbar}>
                                        {modulesProgress.map((m) => {
                                            return (
                                                <div key={m.module_id}>
                                                    <ProgressBar
                                                        showValue={false}
                                                        value={m.completed}
                                                        style={{ height: '6px', width: `100%` }}
                                                    />
                                                </div>
                                            );
                                        })}
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="w-full">
                            {!props.isFinished &&
                                (isLess(props.session.start_date, DateTime.now()) ? (
                                    isSessionStarted(props.session, progress) ? (
                                        <Button className="he-button--primary--md w-full mb-3" onClick={onStart}>
                                            <i className="pi pi-caret-right" style={{ fontSize: '14px' }} />
                                            Commencer la formation
                                        </Button>
                                    ) : (
                                        <Button
                                            className="he-button--primary--md w-full mb-3"
                                            onClick={onContinue}
                                            loading={loadingReport}
                                        >
                                            <i className="pi pi-arrow-circle-right" style={{ fontSize: '16px' }} />
                                            {getNextUnitText()}
                                        </Button>
                                    )
                                ) : (
                                    <Button className="he-button--primary--md w-full mb-3" disabled>
                                        La formation commence le{' '}
                                        {DateTime.fromISO(props.session.start_date).toFormat('DD')}
                                    </Button>
                                ))}
                            {props.isFinished && (
                                <>
                                    <Button className="he-button--secondary-variant--lg w-full" onClick={onStart}>
                                        <i className="pi pi-arrow-circle-right" style={{ fontSize: '16px' }} />{' '}
                                        Récupérer mes documents de formation
                                    </Button>
                                </>
                            )}
                        </div>
                        {!props.isFinished && (
                            <>
                                {currentUnitMeta?.end_date ? (
                                    <span className="he-paragraph--xs gray-400 mt-1">
                                        La partie{' '}
                                        {props.session.unitsConfig.findIndex(
                                            (u) => u.unit_id === currentUnitMeta?.unit_id
                                        ) + 1}{' '}
                                        se termine le {DateTime.fromISO(currentUnitMeta.end_date).toFormat('DDD')}
                                    </span>
                                ) : (
                                    <span className="he-paragraph--xs gray-400 mt-1">&nbsp;</span>
                                )}
                                <span className="he-paragraph--xs gray-400 mt-1">
                                    La formation se termine le{' '}
                                    {DateTime.fromISO(props.participant.end_date).setLocale('fr').toFormat('DDD')}
                                </span>
                            </>
                        )}
                    </>
                )}
            </div>
        </CardWrapper>
    );
};
