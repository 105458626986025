import styled from 'styled-components';


const DEFAULT = {
    right: '#2ECC71',
    wrong: '#E74C3C',
    selected: 'var(--primary-100)',
    background_right: '#EEFBF4',
    background_wrong: '#FDEEED',
    background_selected: 'var(--primary-7)',
}


export const AnswerItem = styled.div<{ defaultColors?: Partial<{
    right: string;
    wrong: string;
    selected: string;
    background_right: string;
    background_wrong: string;
    background_selected: string;
}>, is_correct?: boolean; is_selected?: boolean }>`
    background: ${(props) => {
        return props.is_correct === undefined
            ? props.is_selected
                ? props.defaultColors?.background_selected ? props.defaultColors.background_selected : DEFAULT.background_selected
                : 'white'
            : props.is_correct
            ? props.defaultColors?.background_right ? props.defaultColors.background_right : DEFAULT.background_right
            : props.defaultColors?.background_wrong ? props.defaultColors.background_wrong : DEFAULT.background_wrong;
    }};
    border: ${(props) => {
        return props.is_correct === undefined
            ? props.is_selected
                ? '1px solid ' + (props.defaultColors?.selected ? props.defaultColors.selected : DEFAULT.selected)
                : '1px solid #D0D5DD'
            : props.is_correct
            ? '1px solid '+ (props.defaultColors?.right ? props.defaultColors.right : DEFAULT.right)
            : '1px solid '+ (props.defaultColors?.wrong ? props.defaultColors.wrong : DEFAULT.wrong);
    }};
    border-radius: 8px;
    user-select: none;
    padding: 14px;
    min-height: 48px;
`;
