import { CustomDialog } from '@Components/CustomDialog';
import { BrowserMeta } from '@Components/Popup';
import { SessionContext } from '@Context/Session.context';
import { useApi } from '@Hooks/api';
import { useUser } from '@Hooks/firebase';
import { usePromise } from '@Hooks/promise';
import { useIsPageVisible } from '@Hooks/visibility';
import { SignModule } from '@Pages/Home/components/SignModule';
import { SessionTimeReport } from '@Types/Session';
import { firestore } from '@Utils/config/firebase';
import { getRecordsProgress } from '@Utils/module.utils';
import { getRemainingModuleTime } from '@Utils/progress.utils';
import { Regexes } from '@Utils/regex.utils';
import { collection, onSnapshot } from 'firebase/firestore';
import { DateTime } from 'luxon';
import { Button } from 'primereact/button';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useNavigate, useParams } from 'react-router-dom';
import { FirebaseContext } from './FirebaseContext';

export interface SessionTrackerContextI {
    time: number; // seconds
    moduleTime: number | null;
    timeSinceDownload: number | null;
    saveTime: () => Promise<void>;
    params?: { module_id?: string; activity_id?: string; session_id: string, record_id?: string };
    report?: SessionTimeReport;
    modulesProgress: {
        [module_id: string]: boolean | number;
    };
    recordsTimeProgress: {
        [record_id: string]: number;
    };
    forceLoadSessionReport: (session_id: string) => Promise<SessionTimeReport>;
    loading: boolean;
}

export const SessionTrackerContext = React.createContext<SessionTrackerContextI>({
    time: -1,
    timeSinceDownload: null,
    moduleTime: null,
    loading: false,
    saveTime: async () => {},
    modulesProgress: {},
    recordsTimeProgress: {},
    forceLoadSessionReport: async () => ({
        total: 0,
        session_id: '',
        modules: [],
    }),
    params: undefined,
});

const INTERVAL = 30_000;
const AFK_TIMEOUT = (2 /* HEURES */ * 60 + 30) /* MINUTES*/ * 60 * 1000;

export const SessionTrackerProvider: React.FC<React.PropsWithChildren> = (props) => {
    const [modalVisible, setModalVisible] = useState<boolean>(false);
    const [isIdle, setIsIdle] = useState<boolean>(false);

    const { meta, progress, allRecords } = useUser(true);

    const params = useParams<{ '*': string }>();
    const api = useApi();
    let { session_id, module_id, activity_id, record_id } = useMemo(() => {
        const res = Regexes.url.exec(params['*'] || '')?.groups || {};
        return res;
    }, [params]);

    const [timeSinceDownload, setTimeSinceDownload] = useState<number | null>(null);

    const { loading: sessionLoading, session, modules, participant } = useContext(SessionContext);
    const fbCtx = useContext(FirebaseContext);

    const [time, setTime] = useState<number>(-1);
    const [moduleTime, setModuleTime] = useState<number | null>(null);

    const [report, setReport] = useState<SessionTimeReport>();

    const [tabs, initTabs] = useState<BrowserMeta[] | null>(null);

    const [loadElapsedTime, loading] = usePromise(async (session_id: string, local_module_id?: string) => {
        setModuleTime(null);
        setTimeSinceDownload(null);
        setTime(-1);
        setReport(undefined);
        const res = await api.session_call_timeReport({
            session_id,
        });

        if (res.result !== 'ok') throw new Error(res.result);

        if (local_module_id) {
            const currentModuleExists = local_module_id
                ? res.session.modules.find((m) => m.module_id === local_module_id)
                : null;

            setReport({
                ...res.session,
                modules: [
                    ...res.session.modules,
                    ...(currentModuleExists
                        ? []
                        : [
                              {
                                  module_id: local_module_id,
                                  total: 0,
                                  activities: [],
                                  records: [],
                              },
                          ]),
                ],
            });
        } else {
            setReport(res.session);
        }
        setTimeSinceDownload(DateTime.now().toMillis());
        setTime(res.session.total);
        return res.session;
    });

    useEffect(() => {
        window.addEventListener('beforeunload', registerActivity);
        return onSnapshot(
            collection(firestore.db, `users/${meta.user_id}/browsers`),
            (snap) => {
                initTabs(snap.docs.map((d) => d.data() as BrowserMeta));
            },
            (error) => {
                console.error(error);
                initTabs(null);
            }
        );
    }, []);

    const registerActivity = useCallback(() => {
        if (params['*'] && !sessionLoading) {
            if (session_id) {
                return api
                    .session_call_saveTime(
                        {
                            session_id: session_id,
                            module_id: module_id || null,
                            activity_id: activity_id || null,
                            record_id: record_id || null,
                        },
                        true
                    )
                    .then(() => {
                        console.log('time up ', DateTime.now().toFormat('ff:ss'));
                    });
            }
        }
        return Promise.resolve();
    }, [session_id, module_id, record_id, activity_id, params]);

    const onIdle = () => {
        registerActivity();
        document.title = 'Health Events - Pause';
        setIsIdle(true);
        setModalVisible(true);
    };

    useIdleTimer({
        onIdle,
        timeout: AFK_TIMEOUT,
        throttle: 500,
    });

    const modulesProgress = useMemo(() => {
        if (!session || !report || timeSinceDownload == null || !participant || !progress || !allRecords) {
            fbCtx?.logEvent('moduleProgress', {
                session,
                report,
                timeSinceDownload,
                participant,
                progress,
            });
            return {};
        }

        const completedModules = modules.filter((m) => {
            if (!session?.session_id) return false;

            if (m.type !== 'audit') {
                const activitiesDone = progress.filter(
                    (p) => p.session_id === session?.session_id && p.module_id === m.module_id && p.activity.done
                );
                return m.activities.every((a) => activitiesDone.find((ad) => ad?.activity_id === a.activity_id));
            } else {
                const activitiesIds = m.activities.map((a) => a.activity_id);
                const records = allRecords.filter(
                    (r) => r.module_id === m.module_id && r.session_id === session.session_id
                );

                const initialModule = modules.find((mod) => mod.type === 'audit' && mod.audit_type === 'initial');
                const finalModule = modules.find((mod) => mod.type === 'audit' && mod.audit_type === 'final');

                let min = m.min_records;
                let max = m.max_records;

                if (finalModule && finalModule.module_id === m.module_id && initialModule) {
                    max = allRecords.filter(
                        (r) => r.module_id === initialModule?.module_id && r.session_id === session.session_id
                    ).length;
                    min = max;
                }

                if (min && records.length < min) return false;
                return records.every(
                    (r) =>
                        r.answers.filter((ans) => activitiesIds.includes(ans.activity_id)).length ===
                        m.activities.length && r.submitted
                );
            }
        });

        const res = completedModules.reduce((acc, m) => {
            const remainingModuleTime = getRemainingModuleTime(m.module_id, m, report);
            if (m.minimal_duration != null)
                acc[m.module_id] =
                    remainingModuleTime !== null ? (remainingModuleTime > 0 ? remainingModuleTime : true) : true;
            else if (m.minimal_duration == null) acc[m.module_id] = true;
            else acc[m.module_id] = false;
            return acc;
        }, {} as { [module_id: string]: boolean | number });


        return res;
    }, [session, report, timeSinceDownload, participant, progress, allRecords, modules]);

    const [recordsTimeProgress, setRecordsTimeProgress] = useState<{ [record_id: string]: number }>({}); 

    const isPageVisible = useIsPageVisible();
    const [lastTimeVisible, setLastTimeVisible] = useState<null | number>(null);

    useEffect(() => {
        if (!isPageVisible) {
            if (lastTimeVisible === null) {
                registerActivity().then(() => {
                    setLastTimeVisible(Date.now());
                });
            }
            document.title = 'Health Events - Pause';
        } else {
            if (lastTimeVisible !== null) {
                registerActivity().then(() => {
                    setTimeSinceDownload((prev) => (prev ? prev + (Date.now() - lastTimeVisible) : null));
                    setLastTimeVisible(null);
                });
            }
            if (!isIdle) {
                document.title = 'Health Events';
            }
        }
    }, [isIdle, isPageVisible, lastTimeVisible, registerActivity]);

    const currentUnitInfo = useMemo(() => {
        if (!session) return null;
        const unitIndex = session.formation.units.findIndex((u) => u.modules_ids.includes(module_id));
        return {
            unit: session?.formation.units[unitIndex],
            unitConfig: session?.unitsConfig[unitIndex],
            index: unitIndex,
        };
    }, [session, module_id]);

    const unsignedPreviousUnit = useMemo(() => {
        let unitIndex = -1;
        const hasUnsigned =
            session &&
            participant?.unitsMeta?.some((um, i) => {
                if (!currentUnitInfo) return false;
                const currentUnitIndex = currentUnitInfo?.index;
                if (currentUnitIndex === -1) return false;
                const unitConfig = session.unitsConfig[i];
                if (i < currentUnitIndex) {
                    // should return true if the unit is not signed and the signature is required
                    const res = unitConfig.required_signature ? !um.signature_id : false;
                    if (res) {
                        unitIndex = i;
                    }
                    return res;
                }
                return false;
            });

        return {
            index: unitIndex,
            hasUnsigned,
        };
    }, [currentUnitInfo, participant, module_id]);



    const canRecordTime = useMemo(() => {
        if (!isPageVisible) return false;
        if (unsignedPreviousUnit.hasUnsigned) return false;
        const moduleProgress = modulesProgress?.[module_id];

        if (modulesProgress && module_id) {
            return (
                !isIdle &&
                tabs?.length === 1 &&
                (moduleProgress === false ||
                    moduleProgress === undefined ||
                    (typeof moduleProgress === 'number' && moduleProgress >= 0))
            );
        } else {
            return !isIdle && tabs?.length === 1;
        }
    }, [tabs, isIdle, modulesProgress, module_id, isPageVisible, unsignedPreviousUnit]);

    useEffect(() => {
        if (!canRecordTime) return;

        registerActivity();
        const timer = setInterval(() => {
            if (!canRecordTime) return;
            registerActivity();
        }, INTERVAL);

        return () => {
            clearInterval(timer);
        };
    }, [registerActivity, canRecordTime]);

    useEffect(() => {
        if (!canRecordTime) return;

        let secondTimer = setTimeout(() => {
            if (time !== -1) setTime((prev) => prev + 1000);
        }, 1000);
        return () => {
            clearTimeout(secondTimer);
        };
    }, [time, canRecordTime]);

    useEffect(() => {
        if (session_id && !isIdle) {
            loadElapsedTime(session_id, module_id);
        }
    }, [session_id, module_id, record_id, activity_id, isIdle]);

    const navigate = useNavigate();

    const onContinue = () => {
        document.title = 'Health Events';
        setIsIdle(false);
        setModalVisible(false);
    };

    const onQuit = () => {
        setModalVisible(false);
        setIsIdle(false);
        document.title = 'Health Events';
        navigate('/home');
    };

    useEffect(() => {

        if (!isPageVisible) {
            return;
        }

        const id = setInterval(() => {
            console.log("deps", [
                session_id,
                module_id,
                record_id,
                report,
                timeSinceDownload,
                canRecordTime,
                isPageVisible,
                allRecords
            ])
            if (module_id && report && timeSinceDownload !== null) {
                const module = report.modules.find((m) => m.module_id === module_id);
                if (module) {
                    if (!canRecordTime) return;

                    const diff = DateTime.fromMillis(timeSinceDownload).diffNow().toMillis();

                    setModuleTime(module.total - diff);

                    if (allRecords) {

                        const recordsProgress = getRecordsProgress({
                            module_id,
                            report
                        });

                        if (record_id) {
                            const newProgress = {
                                ...recordsProgress,
                                [record_id]: (recordsProgress[record_id] ?? 0) - diff,
                            };
                            setRecordsTimeProgress(newProgress);
                        } else {
                            setRecordsTimeProgress(recordsProgress);
                        }
                    }
                    
                }
            } else if (!module_id && report && timeSinceDownload !== null) {
                setTimeSinceDownload(Date.now());

            }
        }, 1000);

        return () => {
            clearInterval(id);
        };
    }, [session_id, module_id, record_id, report, timeSinceDownload, canRecordTime, isPageVisible, allRecords]);


    return (
        <SessionTrackerContext.Provider
            value={{
                time,
                report,
                moduleTime,
                loading,
                saveTime: () => {
                    return registerActivity();
                },
                timeSinceDownload,
                modulesProgress,
                recordsTimeProgress: recordsTimeProgress,
                forceLoadSessionReport: (session_id) => {
                    return loadElapsedTime(session_id);
                },
                params: {
                    session_id,
                    module_id,
                    activity_id,
                    record_id,
                },
            }}
        >
            {isIdle && (
                <CustomDialog width={'500px'} visible={modalVisible} onHide={() => setModalVisible(false)}>
                    <div className="flex flex-column align-items-center">
                        <div className="he-header--h1">Êtes-vous présent ?</div>
                        <div className="he-paragraph--regular mt-6 text-center">
                            <p>Vous n'avez effectué aucune action au cours des 2 dernières heures.</p>
                            <p className="mt-1">Pour continuer votre session cliquez sur "Reprendre".</p>
                        </div>
                        <Button className="w-10rem mt-4 he-button--primary--md" onClick={onContinue}>
                            Reprendre
                        </Button>
                        <Button className="w-10rem mt-2 he-button--secondary-variant-nfb--md" onClick={onQuit}>
                            Quitter
                        </Button>
                    </div>
                </CustomDialog>
            )}
            {unsignedPreviousUnit.hasUnsigned && session && (
                <CustomDialog visible onHide={() => {}}>
                    <SignModule
                        isRelance={unsignedPreviousUnit.index}
                        session={session}
                        unit={session.formation.units[unsignedPreviousUnit.index]}
                    />
                </CustomDialog>
            )}
            {props.children}
        </SessionTrackerContext.Provider>
    );
};
