import { Line } from '@Components/Line';
import { SessionContext } from '@Context/Session.context';
import { useUser } from '@Hooks/firebase';
import { useToast } from '@Hooks/toast';
import { Activity, QuizzActivity } from '@Types/Activity';
import { AuditModule, AuditModuleCategory } from '@Types/Module';
import { AuditRecord } from '@Types/User';
import { firestore } from '@Utils/config/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { sortBy } from 'lodash';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { AuditGraph } from './AuditResult/AuditGraph';
import { CategoryScore } from './AuditResult/CategoryScore';
import styled from 'styled-components';

type Props = {
    module: AuditModule;
    allRecords: AuditRecord[];
};
export const AuditResultPage = (props: Props) => {
    const { session, modules } = useContext(SessionContext);
    const user = useUser(true);
    const [saving, setSaving] = useState<boolean>(false);
    const { error, success } = useToast();
    const [loading, setLoading] = useState<boolean>(false);
    const [originalEvaluation, setOriginalEvaluation] = useState<string>('');
    const [evaluation, setEvaluation] = useState<string>('');

    const records = useMemo(() => {
        if (!session) return [];
        return props.allRecords.filter(r => r.module_id === props.module.module_id && r.session_id === session.session_id)
    }, [props.allRecords, props.module.module_id, session])

    const onSaveEvaluation = useCallback(async () => {
        if (!session) return;
        setSaving(true);
        try {
            await updateDoc(
                doc(
                    firestore.db,
                    'formations',
                    session.formation_id,
                    'sessions',
                    session.session_id,
                    'participants',
                    user.meta.user_id
                ),
                {
                    [`evaluation.${props.module.module_id}`]: evaluation,
                }
            );
            success('Evaluation enregistrée');
            setOriginalEvaluation(evaluation);
        } catch (e) {
            console.error(e);
            error("Erreur lors de l'enregistrement de l'évaluation");
        } finally {
            setSaving(false);
        }
    }, [evaluation, session, user.meta.user_id, props.module]);

    useEffect(() => {
        // load evaluation
        if (!session) return;
        setLoading(true);
        const loadEvaluation = async () => {
            const docRef = doc(
                firestore.db,
                'formations',
                session.formation_id,
                'sessions',
                session.session_id,
                'participants',
                user.meta.user_id
            );
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                const data = docSnap.data();
                if (data?.evaluation) {
                    setEvaluation(data.evaluation[props.module.module_id] || '');
                    setOriginalEvaluation(data.evaluation[props.module.module_id] || '');
                }
            }
        };

        loadEvaluation().finally(() => setLoading(false));
    }, [props.module.module_id, session, user.meta.user_id]);

    const getScore = (module: AuditModule, record: AuditRecord) => {
        const categories = Object.fromEntries(module.categories.map((c) => [c.label, [0, 0]]));

        const score = record.answers.reduce((acc, answer) => {
            const activity = module.activities.find((a) => a.activity_id === answer.activity_id) as Activity &
                QuizzActivity;
            if (!activity || !activity.activity_id) return acc;

            const category = module.categories.find((c) => c.activity_ids.includes(activity.activity_id!));

            if (!category) return acc;

            const correctAnswer = activity.answers.findIndex((a) => a.is_response);

            if (answer.answer === 2) return acc;

            const isCorrect = correctAnswer === answer.answer;

            acc[category.label] = [acc[category.label][0] + (isCorrect ? 1 : 0), acc[category.label][1] + 1];

            return acc;
        }, categories);

        return score;
    };

    const scoreByTheme = useMemo(() => {
        const scores = records.map((record) => getScore(props.module, record));

        const catScore: {
            [theme: string]: number;
        } = {};

        for (const category of props.module.categories) {
            const score =
                scores.reduce((acc, s) => {
                    const [correct, total] = s[category.label];
                    return acc + correct / total;
                }, 0) / records.length;

            catScore[category.label] = score;
        }

        return {
            module: props.module,
            score: catScore,
        };
    }, [records, props.module]);

    const initialModuleScore = useMemo(() => {
        if (props.module.audit_type === 'initial') return null;

        const initialModule = modules.find((m) => m.type === 'audit' && m.audit_type === 'initial');

        if (!initialModule || !session) return null;

        const initialRecords = props.allRecords.filter(
            (r) => r.module_id === initialModule.module_id && r.session_id === session.session_id
        );

        const initialScores = initialRecords.map((record) => getScore(initialModule as AuditModule, record));

        const catScore: {
            [theme: string]: number;
        } = {};

        for (const category of props.module.categories) {
            const score =
                initialScores.reduce((acc, s) => {
                    const [correct, total] = s[category.label];
                    return acc + correct / total;
                }, 0) / initialRecords.length;

            catScore[category.label] = score;
        }

        console.log('initialModule', {
            module: initialModule as AuditModule,
            score: catScore,
        });

        return { module: initialModule as AuditModule, score: catScore };
    }, [modules, props.allRecords, props.module.audit_type, props.module.categories, session]);

    const getThemeSummaryDetails = useMemo(() => {
        return sortBy(
            Object.entries(scoreByTheme.score)
                .map(([label, score]) => {
                    const category = props.module.categories.find((c) => c.label === label);
                    if (!category) return null;
                    return [category, score];
                })
                .filter(Boolean) as [AuditModuleCategory, number][],
            ([cat]) => cat.label
        ).map(([cat, score]) => {
            if (props.module.threshold == null) return <></>;

            const comment = score * 100 < props.module.threshold ? cat.failure_text : cat.success_text;
            const initialScore = initialModuleScore ? initialModuleScore.score[cat.label] : 0;

            const didImprove = initialScore < score;

            return (
                <CategoryScore
                    key={cat.category_id}
                    cat={cat}
                    initialModuleScore={initialModuleScore}
                    initialScore={initialScore}
                    score={score}
                    comment={comment}
                    didImprove={didImprove}
                />
            );
        });
    }, [scoreByTheme, props.module, initialModuleScore]);

    return (
        <div className="w-full h-min flex flex-column gap-3" style={{ maxWidth: 751 }}>
            <Container>
                <div className="w-full flex justify-content-between align-items-center">
                    <div className="he-header--h2 gray-800">
                        {props.module.audit_type === 'initial'
                            ? "Résultats d'audit clinique initial"
                            : "Résultats d'audit clinique initial et final"}
                    </div>
                </div>
            </Container>
            <Container>
                <div className="he-header--h3 gray-700">Résultat global</div>
                <Line height={1} className="bg-gray-300 mb-3 mt-2" />
                <AuditGraph
                    categories={props.module.categories}
                    results={initialModuleScore ? [initialModuleScore, scoreByTheme] : [scoreByTheme]}
                />
            </Container>
            <Container>
                <div className="he-header--h3 gray-700">Résultat par catégorie</div>
                <Line height={1} className="bg-gray-300 mb-3 mt-2" />
                <div className="flex flex-column gap-4">{getThemeSummaryDetails}</div>
            </Container>

            <Container>
                <div className="flex justify-content-between">
                    <div className="he-header--h3 gray-700">Votre plan d'amélioration</div>
                    <Button
                        loading={saving}
                        label="Enregistrer"
                        className="he-button--secondary--xxs"
                        icon="pi pi-save"
                        onClick={onSaveEvaluation}
                        disabled={evaluation === originalEvaluation || saving}
                    />
                </div>
                <Line height={1} className="bg-gray-300 mb-3 mt-2" />
                <div className="he-paragraph--small gray-600 mb-3 text-italic">
                    Nous vous invitons à rédiger votre plan d'amélioration de vos pratiques. <br />
                    Si vous le souhaitez, vous pouvez utiliser le champ de texte ci-dessous.
                </div>
                <InputTextarea
                    value={evaluation}
                    onChange={(e) => setEvaluation(e.currentTarget.value)}
                    disabled={loading}
                    style={{ maxWidth: '100%', minHeight: 250 }}
                    className="w-full"
                    placeholder="Saisir ici votre plan d'amélioration"
                />
            </Container>
        </div>
    );
};

const Container = styled.div`
    background: white;
    border: 1px solid #e5e5e5;
    border-radius: 8px;
    padding: 16px 20px;
`;
